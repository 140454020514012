<template>
    <v-breadcrumbs :items="items" large>
        <template v-slot:divider>
            <v-icon v-if="$vuetify.rtl">mdi-chevron-left</v-icon>
            <v-icon v-else>mdi-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>
</template>

<script>
export default {
    name: "KurccBreadcrumbs",
    data() {
        return {
            items: [
                {
                    text: '1. Build',
                    disabled: false,
                },
                {
                    text: '2. Assign',
                    disabled: false,
                },
            ],
        }
    }
}
</script>
